import React, { useEffect, useState } from "react";

import { Button, Empty, Table } from "antd";
import { getAllCategories } from "../../services/dashboard";
import extractErrorMessage from "../../components/shared/basic/formateError";
import { notify } from "../../components/shared/basic/notify";
import { PlusOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";
import { pageTitle } from "../../components/shared/basic/customHead";
import CategoryModal from "../../components/Categories/CategoriesModalCreate";
import CategoryModalDelete from "../../components/Categories/CategoryModalDelete";

export function getUserInitials(fullName: string): string {
  if (!fullName) return "";
  const allNames = fullName.trim().split(" ");
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, "");
  return initials;
}

const CategoriesPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  // const [statusLoading, setStatusLoading] = useState<boolean>(false);

  const [deleteCategory, setDeleteCategory] = useState<{
    value: boolean;
    id: string;
  }>({
    id: "",
    value: false,
  });

  const [data, setData] = useState<
    {
      value: string;
      label: string;
    }[]
  >();
  const [visibleCategory, setVisibleCategory] = useState<{
    type: string;
    value: boolean;
    categoryId?: number;
  }>({ type: "", value: false });

  // const screens = useBreakpoint()

  const onCancelAuthorModal = () => {
    setVisibleCategory({ type: "", value: false });

    handleCategoryList();
  };

  const onCancelCategoryDelete = () => {
    setDeleteCategory({ value: false, id: "" });

    handleCategoryList();
  };

  const handleCategoryList = async () => {
    try {
      setLoading(true);
      const categories = await getAllCategories();
      setData(
        categories?.data?.map((category: any) => ({
          value: category?._id,
          label: category?.name,
        }))
      );
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleCategoryList();
  }, []);

  // const handleStatusChange = (key: number, checked: boolean) => {
  //   if (checked) {
  //     console.log(key);
  //   } else {
  //     console.log(checked);
  //   }
  //   setStatusLoading(checked);
  // };

  const columns = [
    {
      title: (
        <span className="text-xs flex py-2 justify-start md:pl-10">Name</span>
      ),

      key: "label",
      render: (record: any) => {
        return (
          <div className="flex gap-x-4 items-center font-medium md:pl-10 justify-start hover:cursor-pointer hover:underline text-center">
            {record?.label}
          </div>
        );
      },
    },
    // {
    //   title: (
    //     <span className="text-xs flex justify-center text-center">Status</span>
    //   ),

    //   key: "Status_action",
    //   render: (record: any) => {
    //     return (
    //       <div className="flex gap-x-2 items-center justify-center">
    //         <span className="font-medium text-xs">In-Active</span>
    //         <Switch
    //           // checked={is_verified === 1}
    //           loading={statusLoading}
    //           className="bg-lightgray"
    //           onChange={(checked) => handleStatusChange(record.id, checked)}
    //         />
    //         <span className="font-medium text-xs">Active</span>
    //       </div>
    //     );
    //   },
    // },

    {
      title: (
        <span className="text-xs flex justify-center text-center">Action</span>
      ),

      key: "Edit_action",
      render: (record: any) => {
        return (
          <div className="flex font-medium gap-x-2 justify-center ">
            <Button
              onClick={() =>
                setVisibleCategory({
                  type: "Edit",
                  value: true,
                  categoryId: record.value,
                })
              }
            >
              Edit
            </Button>
            <Button
              danger
              onClick={() =>
                setDeleteCategory({
                  id: record.value,
                  value: true,
                })
              }
            >
              Delete Category
            </Button>
          </div>
        );
      },
    },
  ];

  const rowClassName = (_: any, index: number) => {
    return index % 2 === 0 ? "bg-[#131313]" : "bg-bgColor";
  };

  return (
    <div className="bg-readerLightGrayBg rounded-xl ">
      <Helmet>
        <title>{pageTitle("Categories")}</title>
      </Helmet>
      <div className="py-4 px-6 flex flex-col justify-between md:flex-row gap-x-2 items-center">
        <p className="font-semibold flex justify-start text-start text-base lg:text-lg whitespace-nowrap">
          All Categories{" "}
        </p>
        <span>
          <Button
            onClick={() => setVisibleCategory({ type: "Create", value: true })}
            icon={<PlusOutlined />}
          >
            Create Categories
          </Button>
        </span>
      </div>

      <div className="customScrollBar ">
        <Table
          dataSource={data}
          loading={loading}
          className={`tableHeader  rounded-none customTable overflow-auto customScrollBar`}
          columns={columns}
          pagination={false}
          rowClassName={rowClassName}
          locale={{
            emptyText: (
              <span>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>No Authors</span>}
                  className={`h-40 flex justify-center items-center flex-col`}
                />
              </span>
            ),
          }}
        />
      </div>
      <CategoryModal
        type={visibleCategory.type}
        categoryId={visibleCategory.categoryId as number}
        onCancel={onCancelAuthorModal}
        visible={visibleCategory.value as boolean}
      />
      <CategoryModalDelete
        visible={deleteCategory as { value: boolean; id: string }}
        closeModal={onCancelCategoryDelete}
        handleCategoryList={handleCategoryList}
      />
    </div>
  );
};

export default CategoriesPage;
