import React, { useEffect, useState } from "react";

import { Button, Empty, Table } from "antd";
import extractErrorMessage from "../../components/shared/basic/formateError";
import { notify } from "../../components/shared/basic/notify";
import { PlusOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";
import { pageTitle } from "../../components/shared/basic/customHead";
import CategoryModalDelete from "../../components/Categories/CategoryModalDelete";
import { getAllBanner, updateBannerOrder } from "../../services/banner";
import { useParams } from "react-router-dom";
import CreateBannerModal, {
  generateUrlForImageBanner,
} from "../../components/Banner/CreateBannerModal";
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import Cookies from "js-cookie";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { ColumnsType } from "antd/es/table";
import { DragHandle, Row } from "../../components/Banner/DragableTable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

interface DataType {
  value: string;
  heading: string;
  link: string;
  largeScreenImage: string;
  mobileScreenImageWeb: string;
  mobileScreens: string;
  key: string;
  order: number;
}

export function getUserInitials(fullName: string): string {
  if (!fullName) return "";
  const allNames = fullName.trim().split(" ");
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, "");
  return initials;
}

const Banners: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  // const [statusLoading, setStatusLoading] = useState<boolean>(false);

  const [deleteBanner, setDeleteBanner] = useState<{
    value: boolean;
    id: string;
  }>({
    id: "",
    value: false,
  });

  const [data, setData] = useState<DataType[]>([]);
  const [orderChange, setOrderChange] = useState<boolean>(false);
  const [clearImage, setClearImage] = useState(false);
  const [visibleBanner, setVisibleBanner] = useState<{
    type: string;
    value: boolean;
    pageId?: string;
    bannerId?: string;
  }>({ type: "", value: false });
  const params = useParams();

  const defaultMode = Cookies.get("working_mode");

  const columns: ColumnsType<DataType> = [
    { key: "sort", align: "center", width: 80, render: () => <DragHandle /> },
    {
      title: (
        <span className="text-xs flex py-2 justify-start md:pl-10">
          Banner Heading
        </span>
      ),

      key: "label",
      render: (record: any) => {
        return (
          <div className="flex gap-x-4 capitalize items-center font-medium md:pl-10 justify-start hover:cursor-pointer hover:underline text-center">
            {record?.heading}
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs flex py-2 justify-start md:pl-10">Link</span>
      ),

      key: "link",
      render: (record: any) => {
        return (
          <div className="flex gap-x-4 items-center font-medium md:pl-10 justify-start hover:cursor-pointer text-center">
            {record?.link}
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs flex py-2 justify-center">Web Desktop</span>
      ),

      key: "Web Desktop",
      render: (record: any) => {
        return (
          <div className="flex gap-x-4 capitalize items-center font-medium  justify-center hover:cursor-pointer text-center">
            {record?.largeScreenImage ? (
              <CheckIcon className="w-5 text-green-500" />
            ) : (
              <XIcon className="w-5 text-red-500" />
            )}
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs flex py-2 justify-center ">Web Mobile</span>
      ),

      key: "Web Mobile",
      render: (record: any) => {
        return (
          <div className="flex gap-x-4 capitalize items-center font-medium  justify-center hover:cursor-pointer text-center">
            {record?.mobileScreenImageWeb ? (
              <CheckIcon className="w-5 text-green-500" />
            ) : (
              <XIcon className="w-5 text-red-500" />
            )}
          </div>
        );
      },
    },
    {
      title: <span className="text-xs flex py-2 justify-center ">APP</span>,

      key: "APP",
      render: (record: any) => {
        console.log(record, "khaskdjh");
        return (
          <div className="flex gap-x-4 capitalize items-center font-medium justify-center hover:cursor-pointer text-center">
            {record?.mobileScreens ? (
              <CheckIcon className="w-5 text-green-500" />
            ) : (
              <XIcon className="w-5 text-red-500" />
            )}
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs flex justify-center text-center">Action</span>
      ),

      key: "Edit_action",
      render: (record: any) => {
        return (
          <div className="flex font-medium gap-x-2 justify-center ">
            <Button
              onClick={() => {
                setVisibleBanner({
                  type: "Edit",
                  value: true,
                  pageId: params?.id,
                  bannerId: record?.value,
                });
                setClearImage(true);
              }}
            >
              Edit
            </Button>
            <Button
              danger
              onClick={() =>
                setDeleteBanner({
                  id: record.value,
                  value: true,
                })
              }
            >
              Delete Banners
            </Button>
          </div>
        );
      },
    },
  ];

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setData((prevState) => {
        if (prevState) {
          const activeIndex = prevState.findIndex(
            (record) => record.key === active?.id
          );
          const overIndex = prevState.findIndex(
            (record) => record.key === over?.id
          );
          const newState = arrayMove(prevState, activeIndex, overIndex);

          const updatedState = newState.map((item, index) => ({
            ...item,
            order: index,
          }));

          return updatedState;
        } else {
          return [];
        }
      });
      setOrderChange(true);
    }
  };

  useEffect(() => {
    if (orderChange && params.id) {
      handleUpdateBannerOrder(params.id);

      setOrderChange(false);
    }
  }, [orderChange]);

  const baseUrl =
    defaultMode === "live"
      ? process.env.REACT_APP_BASE_URL_LIVE
      : process.env.REACT_APP_BASE_URL;

  // const screens = useBreakpoint()

  const onCancelAuthorModal = () => {
    setVisibleBanner({ type: "", value: false });

    handleBannerList();
  };

  const onCancelCategoryDelete = () => {
    setDeleteBanner({ value: false, id: "" });

    handleBannerList();
  };

  const handleBannerList = async () => {
    try {
      setLoading(true);
      const banners = await getAllBanner(params?.id as string);

      setData(
        banners?.data?.map((banner: any) => ({
          value: banner?._id,
          key: banner?._id,
          heading: banner?.heading,
          link: banner?.link,
          order: banner?.order,
          ...(banner?.web?.largeScreenImage && {
            largeScreenImage: generateUrlForImageBanner(
              `${baseUrl}/`,
              `${banner?._id}/`,
              `webLarge`
            ),
          }),
          ...(banner?.mobile?.mobileScreens && {
            mobileScreens: generateUrlForImageBanner(
              `${baseUrl}/`,
              `${banner?._id}/`,
              `mobile`
            ),
          }),
          ...(banner?.web?.mobileScreenImage && {
            mobileScreenImageWeb: generateUrlForImageBanner(
              `${baseUrl}/`,
              `${banner?._id}/`,
              `webMobile`
            ),
          }),
        }))
      );
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params.id) {
      handleBannerList();
    }
  }, [params]);

  const handleUpdateBannerOrder = async (pageId: string) => {
    try {
      setLoading(true);
      await updateBannerOrder({
        pageId,
        bannerOrders: data?.map((banner: any) => ({
          id: banner.value,
          order: banner.order,
        })),
      });
      await handleBannerList();
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  // const handleStatusChange = (key: number, checked: boolean) => {
  //   if (checked) {
  //     console.log(key);
  //   } else {
  //     console.log(checked);
  //   }
  //   setStatusLoading(checked);
  // };

  const rowClassName = (_: any, index: number) => {
    return index % 2 === 0 ? "bg-[#131313]" : "bg-bgColor";
  };

  return (
    <div className="bg-readerLightGrayBg rounded-xl ">
      <Helmet>
        <title>{pageTitle("Categories")}</title>
      </Helmet>
      <div className="py-4 px-6 flex flex-col justify-between md:flex-row gap-x-2 items-center">
        <p className="font-semibold flex justify-start text-start text-base lg:text-lg whitespace-nowrap">
          All Banners{" "}
        </p>
        <span>
          <Button
            onClick={() => {
              setVisibleBanner({
                type: "Create",
                value: true,
                pageId: params.id,
              });
              setClearImage(true);
            }}
            icon={<PlusOutlined />}
          >
            Create Banner
          </Button>
        </span>
      </div>

      <div className="customScrollBar  ">
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            items={data?.map((i) => i.key)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              rowKey="key"
              loading={loading}
              components={{ body: { row: Row } }}
              columns={columns}
              pagination={false}
              className={`tableHeader  rounded-none customTable overflow-auto customScrollBar`}
              dataSource={data}
              locale={{
                emptyText: (
                  <span className="-z-10">
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={<span>No Banners</span>}
                      className={`h-40 flex justify-center items-center flex-col`}
                    />
                  </span>
                ),
              }}
              rowClassName={rowClassName}
            />
          </SortableContext>
        </DndContext>
      </div>

      <CreateBannerModal
        type={visibleBanner.type}
        page={visibleBanner.pageId}
        banner={visibleBanner.bannerId as string}
        onCancel={onCancelAuthorModal}
        setClearImage={setClearImage}
        clearImage={clearImage}
        visible={visibleBanner.value as boolean}
      />
      <CategoryModalDelete
        type={"Banner"}
        visible={deleteBanner as { value: boolean; id: string }}
        pageId={params.id}
        bannerId={deleteBanner.id}
        closeModal={onCancelCategoryDelete}
        handleCategoryList={handleBannerList}
      />
    </div>
  );
};

export default Banners;
