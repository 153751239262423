import { Endpoints } from "../../network";
import apiClient from "../base";

export interface ILandingPage {
  authorName: string;
  form: any;
  isUpcoming: boolean;
  name: string;
  status: string;
  productSlug: string;
}

export const getAllLandingPage = async () => {
  const response = await apiClient.get(Endpoints.getAllLandingPage);

  return response?.data;
};

export const uploadImage = async (data: any): Promise<any> => {
  const response = await apiClient.post(`${Endpoints.uploadImage}`, data);

  console.log(response, "responseAll");
  return response;
};

export const createLandingPage = async (data: ILandingPage): Promise<any> => {
  const response = await apiClient.post(`${Endpoints.createLandingPage}`, {
    ...data,
  });

  return response;
};

export const updateLandingPage = async (
  data: ILandingPage,
  pageId: string
): Promise<any> => {
  const response = await apiClient.patch(
    `${Endpoints.createLandingPage}/${pageId}`,
    {
      ...data,
    }
  );

  return response;
};

export const getSingleLandingPage = async (id: string): Promise<any> => {
  const response = await apiClient.get(`${Endpoints.getAllLandingPage}/${id}`);

  return response?.data;
};

export const updateLandingPageStatus = async (
  id: string,
  status: string
): Promise<any> => {
  const response = await apiClient.patch(
    `${Endpoints.updateLandingPageStatus}/${id}/status`,
    {
      status,
    }
  );

  return response?.data;
};

export const deleteLandingPage = async (pageId: string) => {
  const response = await apiClient.delete(
    `${Endpoints.getAllLandingPage}/${pageId}`
  );

  return response?.data;
};
