import { PageNames } from "../../components/Banner/CreateBannerModal";
import { Endpoints } from "../../network";
import apiClient from "../base";

export interface IBanner {
  heading: string;
  link: string;
  webLargeScreenImage?: any;
  webMobileScreenImage?: any;
  mobileScreenImage?: any;
}

export interface IBannerPage {
  page: PageNames;
}

export const getAllBanner = async (page: string) => {
  const response = await apiClient.get(`${Endpoints.getAllBanner}/${page}`);

  return response?.data;
};

export const getAllPages = async () => {
  const response = await apiClient.get(Endpoints.getAllPages);

  return response?.data;
};

export const getSingleBanner = async ({ banner }: { banner: string }) => {
  const response = await apiClient.get(
    `${Endpoints.getAllBanner}/details/${banner}`
  );

  return response?.data;
};

export const createBannerPage = async (data: IBannerPage) => {
  const response = await apiClient.post(Endpoints.getAllPages, {
    name: data?.page,
  });

  return response?.data;
};

export const editBannerPage = async (pageId: string, data: IBannerPage) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    const propertyKey = key as keyof IBannerPage;
    const value = data[propertyKey];

    if (value !== undefined) {
      if (Array.isArray(value)) {
        value.forEach((val) => {
          formData.append(`${propertyKey}[]`, val);
        });
      } else {
        formData.append(propertyKey, value);
      }
    }
  });

  const response = await apiClient.put(
    `${Endpoints.getAllPages}/${pageId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response?.data;
};

export const deleteBannerPage = async (pageId: string) => {
  const response = await apiClient.delete(`${Endpoints.getAllPages}/${pageId}`);

  return response?.data;
};

export const createBanner = async ({
  data,
  pageId,
}: {
  data: IBanner;
  pageId: string;
}) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    const propertyKey = key as keyof IBanner;

    if (propertyKey in data) {
      const value = data[propertyKey];

      if (Array.isArray(value)) {
        value.forEach((val) => {
          formData.append(`${propertyKey}[]`, val);
        });
      } else {
        formData.append(propertyKey, value);
      }
    }
  });

  const response = await apiClient.post(
    `${Endpoints.getAllBanner}/${pageId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response?.data;
};

export const editBanner = async ({
  data,
  pageId,
  bannerId,
}: {
  data: IBanner;
  pageId: string;
  bannerId: string;
}) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    const propertyKey = key as keyof IBanner;
    const value = data[propertyKey];

    if (value !== undefined) {
      if (Array.isArray(value)) {
        value.forEach((val) => {
          formData.append(`${propertyKey}[]`, val);
        });
      } else {
        formData.append(propertyKey, value);
      }
    }
  });

  const response = await apiClient.put(
    `${Endpoints.getAllBanner}/${pageId}/${bannerId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response?.data;
};

export const deleteBanner = async (pageId: string, bannerId: string) => {
  const response = await apiClient.delete(
    `${Endpoints.getAllBanner}/${pageId}/${bannerId}`
  );

  return response?.data;
};

export const updateBannerOrder = async ({
  pageId,
  bannerOrders,
}: {
  pageId: string;
  bannerOrders: {
    id: string;
    order: number;
  }[];
}) => {
  const response = await apiClient.post(`${Endpoints.updateBannerOrder}`, {
    pageId,
    bannerOrders,
  });

  return response?.data;
};
