/* eslint-disable no-useless-catch */
import { Account, Client, ID, OAuthProvider } from "appwrite";
import { setToken } from "../../utils/lib/auth";
import { ICreateAccount, ILogin } from "../../utils/appwrite";
import Cookies from "js-cookie";

const appwriteClient = new Client();

const defaultMode = Cookies.get("working_mode");

const projectId =
  defaultMode === "live"
    ? process.env.REACT_APP_APPWRITE_ID_LIVE
    : process.env.REACT_APP_APPWRITE_ID;

const appwriteProjectId = projectId;
const appwriteUrl = process.env.REACT_APP_APPWRITE_URL || "";

appwriteClient.setEndpoint(appwriteUrl).setProject(appwriteProjectId as string);

const account = new Account(appwriteClient);

const createUserAccount = async ({ email, password, name }: ICreateAccount) => {
  return await account.create(ID.unique(), email, password, name);
};

const loginWithGoogle = async () => {
  await logoutIfSessionExists();
  return await account.createOAuth2Session(
    OAuthProvider.Google,
    `${process.env.REACT_APP_API_URL_APP_URL}/#/dashboard?googleLoginCompleted=true`,
    `${process.env.REACT_APP_API_URL_APP_URL}/#/auth/login/`
  );
  // const token = await setTokenInCookies();
};

const loginWithApple = async () => {
  await logoutIfSessionExists();
  return await account.createOAuth2Session(
    OAuthProvider.Apple,
    `${process.env.REACT_APP_API_URL_APP_URL}/#/dashboard?googleLoginCompleted=true`,
    `${process.env.REACT_APP_API_URL_APP_URL}/#/auth/login/`
  );
};

export const setTokenInCookies = async () => {
  try {
    const tokenResponse = await account.createJWT();
    const token = tokenResponse.jwt;
    await setToken(token);
    return token;
  } catch (error) {
    throw error;
  }
};

const logoutIfSessionExists = async () => {
  try {
    const user = await account.getSession("current");
    if (user) {
      return await logout();
    }
  } catch (e: any) {
    if (e.code !== 401 || e.type !== "general_unauthorized_scope") {
      throw e;
    }
  }
  return null;
};

export const checkSessionExists = async () => {
  return await account.getSession("current");
};

const login = async ({ email, password }: ILogin) => {
  return await account.createEmailPasswordSession(email, password);
};

const isLoggedIn = async () => {
  return getCurrentUser();
};

const getCurrentUser = async () => {
  try {
    return await account.getSession("current");
  } catch (e: any) {
    if (e.code !== 401 || e.type !== "general_unauthorized_scope") {
      throw e;
    }
  }
  return null;
};

export const logout = async () => {
  return await account.deleteSession("current");
};

const passwordRecovery = async (email: string) => {
  return await account.createRecovery(
    email,
    `${process.env.REACT_APP_API_URL_APP_URL}/#/auth/reset-password/`
  );
};

const passwordRecoveryConfirmation = async ({
  userId,
  secret_code,
  password,
}: {
  userId: string;
  secret_code: string;
  password: string;
}) => {
  return await account.updateRecovery(userId, secret_code, password);
};

const appwriteService = {
  createUserAccount,
  login,
  isLoggedIn,
  getCurrentUser,
  logout,
  loginWithGoogle,
  setTokenInCookies,
  account,
  loginWithApple,
  passwordRecovery,
  passwordRecoveryConfirmation,
  logoutIfSessionExists,
};

export default appwriteService;
