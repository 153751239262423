import { Input, Modal } from "antd";
import React, { useEffect, useState } from "react";

import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
import { notify } from "../../shared/basic/notify";
import { formateErrorObject } from "../../shared/formateErrorObject";
import {
  addCategories,
  getSingleCategories,
  updateCategories,
} from "../../../services/dashboard";

import extractErrorMessage from "../../shared/basic/formateError";

interface ModalComponentProps {
  visible: boolean;
  onCancel: () => void;
  isClosable?: boolean;
  type?: string;
  categoryId?: number;
}

const CategoryModal: React.FC<ModalComponentProps> = ({
  visible,
  onCancel,
  type,
  categoryId,
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [formData, setFormData] = useState<{
    name: string;
  }>({
    name: "",
  });

  const [errors, setErrors] = useState({
    name: "",
  });

  const handleCancel = () => {
    onCancel();
    setErrors({
      name: "",
    });
    setFormData({
      name: "",
    });
  };
  const handleOk = () => {
    setConfirmLoading(true);

    setTimeout(() => {
      setConfirmLoading(false);
      handleCancel();
    }, 2000);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const validationErrors = {
      name: "",
    };
    if (!formData.name) {
      validationErrors.name = "Please Enter Category name";
    }

    setErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      try {
        setConfirmLoading(true);
        if (type !== "Edit") {
          await addCategories({
            ...formData,
            name: formData?.name,
          });
        } else {
          await updateCategories(
            {
              ...formData,
              name: formData?.name,
            },
            categoryId?.toString() as string
          );
        }

        notify("Category Created Successfully", "success");
        handleCancel();
      } catch (error: any) {
        const message = formateErrorObject(error);
        notify(message, "error");
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  // const handleAllProducts = async (type: string) => {
  //   const isLong = type === "long";
  //   try {
  //     const products = await getAllProducts({
  //       book_type: isLong ? "hardcover" : "digital",
  //       category_id: 6,
  //     });

  //     setProducts(
  //       products?.data?.map((product: any) => ({
  //         productId: isLong ? product?.digital_pair?.id : product?.id,
  //         hardcoverProductId: isLong ? product?.id : product?.id,
  //         digitalProductId: isLong ? product?.digital_pair?.id : product?.id,
  //         slug: product?.slug,
  //         name: product?.name,
  //       }))
  //     );
  //   } catch (error) {
  //     console.error("API call error:", error);
  //   }
  // };
  // useEffect(() => {
  //   if (formData.type && formData.type !== "") {
  //     handleAllProducts(formData.type);
  //   }
  // }, [formData.type]);

  const handleSingleBook = async (categoryId: string) => {
    try {
      //   setLoading(true);
      const response = await getSingleCategories(categoryId);
      const data = response?.data;

      setFormData({
        ...formData,
        name: data?.name,
      });
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      //   setLoading(false);
    }
  };

  useEffect(() => {
    if (categoryId) {
      handleSingleBook(categoryId.toString());
    }
  }, [categoryId]);

  return (
    <Modal
      title=""
      open={visible}
      centered={true}
      onOk={handleOk}
      closable={false}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      footer={false}
      className="customLoginModal bg-bgColor bg-opacity-70  pt-5  relative rounded-2xl border-2 border-borderDarkGray backdrop-filter backdrop-blur-md "
    >
      <div className="flex items-center flex-col justify-center px-5 md:px-10">
        <div className={` z-30  flex flex-col gap-y-1 w-full `}>
          <div className="pb-3 flex flex-col gap-y-2 ">
            <h1 className="text-xl flex justify-center gap-x-2 font-medium lg:font-normal lg:text-2xl mt-1 leading-10 text-fadedWhite text-center w-full">
              {type} Category
            </h1>
          </div>

          <form className="flex flex-col mt-2 gap-y-3 w-full ">
            <div className="col-span-1 flex-col flex gap-y-.5">
              <Input
                type="text"
                name="name"
                autoComplete="off"
                style={{
                  backgroundColor: "#080808",
                }}
                value={formData.name}
                placeholder="Category Name"
                onChange={handleChange}
                size="large"
                className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
              />
              {errors.name && (
                <p className="text-red-500 text-xs">{errors.name}</p>
              )}
            </div>

            <span className="flex justify-center gap-x-2 mt-2 pb-4">
              <ButtonDefault
                size={4}
                variant={ButtonVariants.GRAY}
                className="p-1 w-1/3"
                rounded={true}
                onClick={() => handleCancel()}
              >
                <span className="flex text-sm font-bold gap-x-2 px-4 py-1 whitespace-nowrap">
                  Cancel
                </span>
              </ButtonDefault>
              <ButtonDefault
                size={4}
                variant={ButtonVariants.WHITE}
                className="p-1 w-2/3"
                disabled={confirmLoading}
                loading={confirmLoading}
                rounded={true}
                onClick={handleSubmit}
              >
                <span className="flex text-sm font-bold gap-x-2 px-4 py-1 whitespace-nowrap">
                  {type} Category
                </span>
              </ButtonDefault>
            </span>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default CategoryModal;
