import React, { useEffect, useState } from "react";
import type { RadioChangeEvent } from "antd";
import { Radio } from "antd";
import clsx from "clsx";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const ToggleMode: React.FC = () => {
  const [mode, setMode] = useState("staging");

  const router = useNavigate();
  const handleModeChange = (e: RadioChangeEvent) => {
    const val = e.target.value;
    Cookies.set("working_mode", val);
    setMode(val);
    router(0);
  };

  useEffect(() => {
    const defaultMode = Cookies.get("working_mode");
    if (defaultMode === "live") {
      setMode("live");
    } else {
      setMode("staging");
    }
  }, [Cookies]);

  return (
    <Radio.Group
      onChange={handleModeChange}
      value={mode}
      className="mb-6 bg-bgLightGray border-bgLightGray text-white flex gap-2 rounded-md overflow-hidden border-2 auth-style"
    >
      <Radio.Button
        className={clsx(
          "w-1/2 border-none rounded-md text-center",
          mode === "staging"
            ? "bg-white text-green-600 font-medium"
            : "bg-transparent text-white "
        )}
        value="staging"
      >
        Staging Mode
      </Radio.Button>
      <Radio.Button
        className={clsx(
          "w-1/2 border-none rounded-md text-center",
          mode === "live"
            ? "bg-white font-medium"
            : "bg-transparent text-white "
        )}
        value="live"
      >
        Live Mode
      </Radio.Button>
    </Radio.Group>
  );
};

export default ToggleMode;
