import { PhotographIcon } from "@heroicons/react/outline";
import { Upload } from "antd";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

const { Dragger } = Upload;

interface ImageUploaderProps {
  maxFileCount?: number;
  uploadAction: string;
  onChange: (file: File | null) => void;
  defaultImage?: string | null;
  clearImage?: boolean;
  accept?: string;
  updateText?: string;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  maxFileCount = 5,
  uploadAction,
  onChange,
  defaultImage,
  accept = ".jpg, .jpeg, .png, .webp",
  updateText = "Drag and drop files here or click to upload",
  clearImage = false,
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    if (defaultImage) {
      setFileList([
        {
          uid: "-1",
          name: "defaultImage",
          status: "done",
          url: defaultImage,
        },
      ]);
    }
  }, [defaultImage]);

  useEffect(() => {
    if (!clearImage) {
      setFileList([]);
      onChange(null);
    }
  }, [clearImage]);

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);

    if (newFileList && newFileList.length > 0) {
      const firstFile = newFileList[0];

      if (firstFile && firstFile.originFileObj) {
        onChange(firstFile.originFileObj as File);
      } else {
        onChange(null);
      }
    } else {
      onChange(null);
    }
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url;
    if (!src && file.originFileObj) {
      src = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }

    if (typeof src !== "string") {
      console.error("File URL is not a string:", src);
      return;
    }

    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    if (imgWindow) {
      imgWindow.document.write(image.outerHTML);
    } else {
      console.error("Failed to open preview window");
    }
  };

  return (
    <Dragger
      action={uploadAction}
      listType="picture-card"
      accept={accept}
      className={clsx(
        fileList.length ? "hideDragAndDrop" : "",
        "uploadToolTipDisable group"
      )}
      fileList={fileList}
      onChange={handleChange}
      onPreview={onPreview}
    >
      {fileList.length < maxFileCount && (
        <div className="group-hover:text-primary">
          <span className="flex justify-center">
            <PhotographIcon className="w-10" />
          </span>
          <div style={{ marginTop: 8 }}>{updateText}</div>
        </div>
      )}
    </Dragger>
  );
};

export default ImageUploader;
