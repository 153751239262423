import React, { useState, useEffect } from "react";
import { Button, Input, Tabs } from "antd";
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Books from "../../components/ProductsComp/Books";
import { Helmet } from "react-helmet";
import { pageTitle } from "../../components/shared/basic/customHead";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { ShouldRender } from "../../components/shared/basic/ShouldRender";
import { useRecoilState } from "recoil";
import { searchKeywords } from "../../atoms/searchKeywords";
import BookModal from "../../components/ProductsComp/BookModal";
import { SearchOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;

const ProductsPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState("1");
  const [searchKeyword, setSearchKeyword] = useRecoilState(searchKeywords);
  const [visibleBook, setVisibleBook] = useState<{
    type: string;
    value: boolean;
    bookId?: number;
  }>({ type: "", value: false });
  const screens = useBreakpoint();

  const handleTabChange = (key: string) => {
    setActiveTab(key);
    setSearchParams({ tab: key });
  };

  const onCancelBookModal = () => {
    setVisibleBook({ type: "", value: false });
  };

  useEffect(() => {
    if (searchParams.get("tab")) {
      setActiveTab(searchParams.get("tab") as string);
    }
  }, [params.tab, navigate]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchKeyword = e.target.value;
    setSearchKeyword(newSearchKeyword);
  };

  return (
    <div>
      <Helmet>
        <title>{pageTitle("Products")}</title>
      </Helmet>
      <div className="relative">
        <div className="absolute z-20 flex right-0 -top-2 justify-end">
          <div className="flex gap-x-2 items-center">
            <ShouldRender check={screens?.md}>
              <div>
                <Input
                  size="large"
                  placeholder="Search for Books and Courses"
                  className="rounded-lg border-none customSearch text-gray-100 text-sm placeholder:text-gray-400 h-10 bg-readerLightGrayBg"
                  value={searchKeyword}
                  prefix={
                    <SearchOutlined className="w-5 h-5 pr-1 text-[#A6A6A6]" />
                  }
                  onChange={handleInputChange}
                />
              </div>
            </ShouldRender>
            <div>
              <Button
                onClick={() => setVisibleBook({ type: "Create", value: true })}
                size="middle"
                className="h-10"
              >
                <span>Create Book</span>
              </Button>
            </div>
          </div>
        </div>

        <Tabs
          activeKey={activeTab}
          onChange={handleTabChange}
          className="customTab"
        >
          <TabPane tab="Books" key="1">
            {activeTab === "1" && (
              <Books
                bookType="long"
                visible={visibleBook.value && activeTab === "1"}
                setVisibleBook={setVisibleBook}
              />
            )}
          </TabPane>
          <TabPane tab="Short Books" key="2">
            {activeTab === "2" && (
              <Books
                bookType="short"
                visible={visibleBook.value && activeTab === "2"}
                setVisibleBook={setVisibleBook}
              />
            )}
          </TabPane>
          <TabPane tab="Coming Soon" key="3">
            {activeTab === "3" && (
              <Books
                bookType="comingSoon"
                visible={visibleBook.value && activeTab === "3"}
                setVisibleBook={setVisibleBook}
              />
            )}
          </TabPane>
        </Tabs>
      </div>
      <Outlet />
      <BookModal
        onCancel={onCancelBookModal}
        visible={visibleBook.value as boolean}
        type={visibleBook.type}
        bookId={visibleBook.bookId as number}
      />
    </div>
  );
};

export default ProductsPage;
