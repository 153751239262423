import clsx from "clsx";
import React from "react";

export enum ButtonVariants {
  WHITE = "WHITE",
  PRIMARY = "PRIMARY",
  UNSTYLED = "UNSTYLED",
  GRAY = "GRAY",
}

type ButtonSize = 0 | 1 | 2 | 3 | 4 | 5;

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size: ButtonSize;
  variant: ButtonVariants;
  loading?: boolean;
  rounded?: boolean;
  roundedMd?: boolean;
  roundedSm?: boolean;
  spinColor?: string;
  enableScaling?: boolean;
}

const Spinner = ({ color = "currentColor" }: { color?: string }) => (
  <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke={color}
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill={color}
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

const ButtonDefault: React.FC<ButtonProps> = ({
  children,
  variant,
  size,
  loading = false,
  rounded = false,
  roundedMd = false,
  roundedSm = false,
  spinColor = "currentColor",
  enableScaling = true,
  className,
  type = "button", // Default type to "button"
  ...props
}) => {
  const commonStyles = {
    [ButtonVariants.WHITE]:
      "text-gray-700 font-medium bg-white border border-gray-300 hover:bg-gray-50 focus:ring-offset-2 focus:ring-2 disabled:text-gray-400 disabled:bg-gray-200 disabled:cursor-not-allowed",
    [ButtonVariants.GRAY]:
      "text-fadeWhite font-medium bg-bgDarkGray border border-bgDarkGray hover:bg-dividerColor focus:ring-offset-2 focus:ring-2 disabled:text-gray-400 disabled:bg-gray-200 disabled:cursor-not-allowed",
    [ButtonVariants.PRIMARY]:
      "text-white bg-primary border-primary hover:bg-darkPrimary hover:ring-primary font-medium focus:ring-offset-2 focus:ring-2 disabled:text-gray-400 disabled:bg-gray-200 disabled:cursor-not-allowed",
    [ButtonVariants.UNSTYLED]: "font-inter",
  };

  const commonButtonSizes = {
    "0": "px-[4px] py-[2px] text-xs",
    "1": "px-[11px] py-[7px] text-xs",
    "2": "px-[13px] py-[9px] text-sm",
    "3": "px-[17px] py-[9px] text-sm",
    "4": "px-[17px] py-[9px] text-base",
    "5": "px-[25px] py-[13px] text-base",
  };

  const buttonClasses = clsx(
    commonButtonSizes[size],
    commonStyles[variant],
    className,
    rounded && "rounded-xl",
    rounded && roundedMd && "lg:rounded-2xl",
    rounded && roundedSm && "lg:rounded-lg",
    !rounded && "rounded-lg",
    enableScaling &&
      "transition duration-300 ease-in-out transform hover:scale-105",
    "flex justify-center items-center",
    enableScaling &&
      "focus:ring-2 focus:ring-offset-2 focus:ring-white focus:ring-opacity-50"
  );

  return (
    <button type={type} className={buttonClasses} {...props}>
      {loading && <Spinner color={spinColor} />}
      {children}
    </button>
  );
};

export default ButtonDefault;
