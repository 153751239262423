import React from "react";

interface ParseProps {
  text: string;
  maxWords?: number;
  className?: string;
}

const ParserV2: React.FC<ParseProps> = ({ text, maxWords, className }) => {
  let parsedText = text;
  let ellipsis = "";

  if (maxWords !== undefined) {
    parsedText = text?.split(" ")?.slice(0, maxWords)?.join(" ");
    ellipsis = text?.split(" ")?.length > maxWords ? "..." : "";
  }

  return (
    <p className={` ${className}`}>
      {parsedText}
      {ellipsis}
    </p>
  );
};

export default ParserV2;
