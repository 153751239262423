import React, { useEffect, useMemo, useState } from "react";
import { Button, Empty, Popover, Table } from "antd";
import { IAuthor } from "../../utils/interfaces/Author";
import { getAllAuthors, getProductsList } from "../../services/dashboard";
import extractErrorMessage from "../../components/shared/basic/formateError";
import { notify } from "../../components/shared/basic/notify";
import { PlusOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";
import { pageTitle } from "../../components/shared/basic/customHead";
import ParserV2 from "../../components/shared/Parser";
import AuthorModal from "../../components/Author/AuthorModal";
import CategoryModalDelete from "../../components/Categories/CategoryModalDelete";

export function getUserInitials(fullName: string): string {
  if (!fullName) return "";
  const allNames = fullName.trim().split(" ");
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, "");
  return initials;
}

const AuthorsPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IAuthor[]>();
  const [books, setBooks] = useState<
    {
      _id: string;
      title: string;
      author_list: {
        _id: string;
        name: string;
        designation: string[];
        description: string[];
        longDescription: string;
        avatar: string;
      }[];
    }[]
  >([]);

  const [visibleAuthor, setVisibleAuthor] = useState<{
    type: string;
    value: boolean;
    authorId?: number;
  }>({ type: "", value: false });
  const [deleteAuthor, setDeleteAuthor] = useState<{
    value: boolean;
    id: string;
  }>({
    id: "",
    value: false,
  });

  const handleProductList = async (keyword?: string) => {
    try {
      setLoading(true);
      const response = await getProductsList({
        page: 1,
        name: keyword,
        limit: 500,
      });

      const bookList = response?.data?.data.map((book: any) => ({
        _id: book._id,
        title: book.title,
        author_list: book.author_list,
      }));

      setBooks(bookList);
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const updatedAuthors = useMemo(() => {
    const newAuthors = data?.map((author) => {
      const updatedAuthor = { ...author, books: [] as string[] };

      books?.forEach((book) => {
        book.author_list.forEach((bookAuthor) => {
          if (bookAuthor._id === author.id) {
            updatedAuthor.books.push(book.title);
          }
        });
      });

      return updatedAuthor;
    });

    return newAuthors;
  }, [books, data]);

  const onCancelAuthorModal = () => {
    setVisibleAuthor({ type: "", value: false });
    handleAuthorsList();
  };

  const handleAuthorsList = async () => {
    try {
      setLoading(true);
      const response = await getAllAuthors();
      setData(
        response?.map((author: any) => {
          return {
            id: author?._id,
            name: author?.name,
            key: author?._id,
            designation: author?.designation,
            avatar: author?.avatar,
            email: author?.email,
            description: author?.description,
            longDescription: author?.longDescription,
          };
        })
      );
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const onCancelCategoryDelete = () => {
    setDeleteAuthor({ value: false, id: "" });
    handleAuthorsList();
  };

  useEffect(() => {
    handleAuthorsList();
    handleProductList();
  }, []);

  const columns = [
    {
      title: (
        <span className="text-xs flex py-2 justify-start md:pl-10">Name</span>
      ),
      key: "name",
      render: (record: any) => {
        return (
          <div className="flex gap-x-4 items-center font-medium md:pl-10 justify-start hover:cursor-pointer hover:underline text-center">
            {record?.name}
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs flex py-2 justify-start md:pl-5">Email</span>
      ),
      dataIndex: "email",
      key: "email",
      render: (email: string) => {
        return <div>{email}</div>;
      },
    },
    {
      title: (
        <span className="text-xs flex py-2 justify-start md:pl-5">
          Designation
        </span>
      ),
      dataIndex: "designation",
      key: "designation",
      render: (text: string[]) => {
        return (
          <div className="flex justify-start md:pl-10">
            <div className="flex flex-col font-medium justify-start">
              <ul className="inline-block">
                {text.map((designation: string, index: number) => (
                  <Popover key={designation + index} content={designation}>
                    <li className="list-disc">
                      <ParserV2 text={designation} maxWords={10} />
                    </li>
                  </Popover>
                ))}
              </ul>
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs flex  justify-start md:pl-5">Description</span>
      ),
      dataIndex: "description",
      key: "description",
      render: (text: string[]) => {
        return (
          <div className="flex justify-start md:pl-10">
            <div className="flex flex-col font-medium justify-start">
              <ul className="inline-block">
                {text.map((description: string, index: number) => (
                  <li className="list-disc " key={description + index}>
                    <Popover content={description}>
                      <ParserV2 text={description} maxWords={10} />
                    </Popover>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        );
      },
    },
    {
      title: <span className="text-xs flex  justify-start md:pl-5">Books</span>,
      dataIndex: "books",
      key: "books",
      render: (text: string[]) => {
        return (
          <div className="flex justify-start md:pl-10">
            <div className="flex flex-col font-medium justify-start">
              <ul className="inline-block">
                {text.map((description: string, index: number) => (
                  <li className="list-disc " key={description + index}>
                    <Popover content={description}>
                      <ParserV2 text={description} maxWords={10} />
                    </Popover>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs flex justify-center text-center">Edit</span>
      ),
      key: "Edit_action",
      render: (record: any) => {
        return (
          <div className="flex font-medium gap-x-2 justify-center ">
            <Button
              onClick={() =>
                setVisibleAuthor({
                  type: "Edit",
                  value: true,
                  authorId: record.id,
                })
              }
            >
              Edit
            </Button>
            <Button
              danger
              onClick={() =>
                setDeleteAuthor({
                  id: record.id,
                  value: true,
                })
              }
            >
              Delete Author
            </Button>
          </div>
        );
      },
    },
  ];

  const rowClassName = (_: any, index: number) => {
    return index % 2 === 0 ? "bg-[#131313]" : "bg-bgColor";
  };

  return (
    <div className="bg-readerLightGrayBg rounded-xl ">
      <Helmet>
        <title>{pageTitle("Authors")}</title>
      </Helmet>
      <div className="py-4 px-6 flex flex-col justify-between md:flex-row gap-x-2 items-center">
        <p className="font-semibold flex justify-start text-start text-base lg:text-lg whitespace-nowrap">
          All Authors{" "}
        </p>
        <span>
          <Button
            onClick={() => setVisibleAuthor({ type: "Create", value: true })}
            icon={<PlusOutlined />}
          >
            Create Author
          </Button>
        </span>
      </div>

      <div className="customScrollBar ">
        <Table
          dataSource={updatedAuthors?.map((author) => ({
            ...author,
            key: author.id,
          }))}
          loading={loading}
          className={`tableHeader  rounded-none customTable overflow-auto customScrollBar`}
          columns={columns}
          pagination={false}
          rowClassName={rowClassName}
          locale={{
            emptyText: (
              <span>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>No Authors</span>}
                  className={`h-40 flex justify-center items-center flex-col`}
                />
              </span>
            ),
          }}
        />
      </div>
      <AuthorModal
        type={visibleAuthor.type}
        authorId={visibleAuthor.authorId as number}
        onCancel={onCancelAuthorModal}
        visible={visibleAuthor.value as boolean}
      />

      <CategoryModalDelete
        type={"Author"}
        visible={deleteAuthor as { value: boolean; id: string }}
        closeModal={onCancelCategoryDelete}
        handleCategoryList={handleAuthorsList}
      />
    </div>
  );
};

export default AuthorsPage;
