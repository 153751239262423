import { Input, Modal } from "antd";
import React, { useState } from "react";
import ReactCodeInput from "react-code-input";
import { useRecoilState } from "recoil";

import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
import { ShouldRender } from "../../shared/basic/ShouldRender";
import {
  forgotPasswordCheckOtp,
  forgotPasswordResetPassword,
  forgotPasswordSendEmail,
} from "../../../services/forgotPassword";
import { formatErrorObject } from "../../../utils/lib/auth";
import EmailIcon from "../../../assets/Icons/Mail";
import PasswordIcon from "../../../assets/Icons/password";
import LockCheckIcon from "../../../assets/Icons/LockCheck";
import { authModalControl } from "../../../atoms/toggleTheme/authModalControl";
import { XIcon } from "@heroicons/react/outline";

interface ModalComponentProps {
  visible: boolean;
  onCancel: () => void;
  isClosable?: boolean;
}

const ForgotPasswordModal: React.FC<ModalComponentProps> = ({
  visible,
  onCancel,
  isClosable = true,
}) => {
  const [forgotPasswordStep, setForgotPasswordStep] = useState(0);
  const [, setVisible] = useRecoilState(authModalControl);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState({
    reEnterPassword: "",
    password: "",
  });
  const [passwordErrors, setPasswordErrors] = useState({
    reEnterPassword: "",
    password: "",
  });
  const [emailErrors, setEmailErrors] = useState({
    email: "",
  });

  const submitPassword = async (e: React.FormEvent) => {
    e.preventDefault();

    const validationErrors = {
      reEnterPassword: "",
      password: "",
    };
    if (!password.password) {
      validationErrors.password = "Password is required";
    }
    if (!password.reEnterPassword) {
      validationErrors.reEnterPassword = "Re-Enter Password is requires";
    } else if (password.password !== password.reEnterPassword) {
      validationErrors.reEnterPassword =
        "Password didn`t matched! Please try again";
    } else if (password.password.length < 8) {
      validationErrors.password = "Password must be at least 8 characters long";
    }

    setPasswordErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      try {
        setLoading(true);
        await forgotPasswordResetPassword({
          verification_code: Number(otp),
          password: password.password,
        });
        setForgotPasswordStep(3);
        setTimeout(() => {
          setVisible((prevState: any) => ({
            ...prevState,
            login: true,
            signUp: false,
            forgotPassword: false,
          }));
        }, 3000);
      } catch (error: any) {
        const message = formatErrorObject(error);
        setMessage(message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleOk = () => {
    setConfirmLoading(true);

    setTimeout(() => {
      setConfirmLoading(false);
      onCancel();
    }, 2000);
  };

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleClose = () => {
    onCancel();
    setForgotPasswordStep(0);
    setOtp("");
    setPassword({
      reEnterPassword: "",
      password: "",
    });
    setEmail("");
    setMessage("");
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (name === "registeredEmail") {
      setEmail(value);
    } else if (name === "password" || name === "reEnterPassword") {
      setPassword({
        ...password,
        [name]: value,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const submitEmail = async (e: React.FormEvent) => {
    e.preventDefault();

    const validationErrors = {
      email: "",
    };

    if (!email) {
      validationErrors.email = "Email or phone number is required";
    } else if (!isValidEmailOrPhone(email)) {
      validationErrors.email = "Invalid email or phone number";
    }

    setEmailErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      try {
        setLoading(true);
        await forgotPasswordSendEmail({
          send_code_by: isValidEmail(email) ? "email" : "phone",
          email_or_phone: email,
        });
        setForgotPasswordStep(1);
        setEmailErrors({
          email: "",
        });
        setMessage("");
      } catch (error: any) {
        const message = formatErrorObject(error);
        validationErrors.email = message;
        setEmailErrors(validationErrors);
        setMessage(message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePhoneInput = (value: string) => {
    const cleanedValue = value.replace(/\D/g, ""); // Remove non-numeric characters

    // Ensure only numbers are set as OTP
    if (/^\d+$/.test(cleanedValue)) {
      setOtp(cleanedValue);
      // Reset any previous error message
      setMessage("");
    } else {
      // Set error message for non-numeric input
      setMessage("Please enter valid OTP");
    }
  };

  const handleCheckOtp = async (e: React.FormEvent, otp: number) => {
    e.preventDefault();
    try {
      setLoading(true);
      await forgotPasswordCheckOtp({
        otp,
      });
      setForgotPasswordStep(2);
    } catch (error: any) {
      const message = formatErrorObject(error);
      setMessage(message);
    } finally {
      setLoading(false);
    }
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const isValidEmailOrPhone = (value: string) => {
    // Your combined validation logic for email and phone number
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneRegex = /^\d{10}$/;

    return emailRegex.test(value) || phoneRegex.test(value);
  };

  return (
    <Modal
      title=""
      open={visible}
      centered={true}
      onOk={handleOk}
      closable={false}
      confirmLoading={confirmLoading}
      onCancel={handleClose}
      footer={false}
      className="customLoginModal bg-opacity-70  pt-5 px-10 rounded-2xl border-2 border-borderDarkGray backdrop-filter backdrop-blur-md "
    >
      <div className="flex items-center gap-y-10 flex-col justify-center">
        <div className={` z-30  flex flex-col gap-y-1 w-full`}>
          <div className="relative pt-4">
            {/* <span className=' flex justify-center w-full'>
              <Image
                src={ZebraLearnLogo}
                alt='Logo'
                className='object-contain h-5 w-full'
              />
            </span> */}
            <ShouldRender check={isClosable}>
              <span
                onClick={() => {
                  handleClose();
                }}
                className="absolute right-0 top-[40%] cursor-pointer"
              >
                <XIcon className="w-6 h-6 text-fadedWhite" />
              </span>
            </ShouldRender>
          </div>
          <div className="flex items-center gap-y-10 flex-col justify-center">
            <div className="z-30  py-6 flex flex-col w-full gap-y-1">
              <ShouldRender check={forgotPasswordStep === 0}>
                <h1 className="text-xl text-fadedWhite font-medium lg:font-normal lg:text-3xl mt-1 leading-10  text-center w-full">
                  Reset your{" "}
                  <b className="text-primary font-medium">password</b>
                </h1>
                <span className="text-textGray text-center text-lg">
                  {`Don't worry! We got your back.`}
                </span>
                <form
                  onSubmit={submitEmail}
                  className="flex flex-col  gap-2 mt-10 w-full"
                >
                  <div className="col-span-1 flex-col flex gap-y-.5">
                    <Input
                      type="text"
                      name="registeredEmail"
                      placeholder="Email"
                      style={{
                        backgroundColor: "#141414",
                      }}
                      autoComplete="off"
                      value={email}
                      onChange={handleChange}
                      size="large"
                      prefix={
                        <span className="pr-2">
                          <EmailIcon filled={true} />
                        </span>
                      }
                      className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
                    />
                    {(emailErrors.email || message) && (
                      <p className="text-red-500">
                        {message ?? emailErrors.email}
                      </p>
                    )}
                  </div>

                  <span className="flex justify-center mt-2">
                    <ButtonDefault
                      size={4}
                      variant={ButtonVariants.WHITE}
                      className="p-1 w-full"
                      disabled={loading}
                      loading={loading}

                      // onClick={() => router.push('/auth/login')}
                    >
                      <span className="flex text-sm font-bold gap-x-2 px-4 py-1 whitespace-nowrap">
                        Send OTP
                      </span>
                    </ButtonDefault>
                  </span>

                  {/* <ShouldRender check={!onCancel}>
                <span className='flex justify-center'>
                  <span
                    onClick={() => {
                      if (onCancel) {
                        handleModalClose();
                      } else {
                        router.push('/auth/login');
                      }
                    }}
                    className='text-center  text-primary  whitespace-nowrap px-1 hover:font-bold underline cursor-pointer text-sm'
                  >
                    GO BACK
                  </span>
                </span>
              </ShouldRender> */}

                  <span className="text-center text-sm text-fadedWhite mt-2">
                    OTP will be send on your mail
                  </span>
                </form>
              </ShouldRender>
              <ShouldRender check={forgotPasswordStep === 1}>
                <h1 className="text-xl text-fadedWhite font-medium lg:font-normal lg:text-3xl mt-1 leading-10  text-center w-full">
                  Verify
                  <b className="text-primary font-medium file:">Email</b>
                </h1>
                <span className="text-textGray text-center text-lg">
                  Please enter OTP, that we sent to your Email
                </span>
                <div>
                  <div className="flex flex-col justify-center py-5 w-full">
                    <div className="flex justify-center ">
                      <ReactCodeInput
                        type="tel"
                        fields={6}
                        value={otp}
                        onChange={(otp: string) => handlePhoneInput(otp)}
                        name="OTPInput"
                        inputMode="tel"
                        autoFocus={true}
                        className="grid grid-cols-3 xxs:grid-cols-6 gap-2 xs:gap-4 pb-0.5 w-full custom-input"
                        inputStyle={{
                          padding: "0.75rem",
                          color: "#a7a7a7",
                          textAlign: "center",
                          border: "1px solid #D1D5DB",
                          background: "#141414",
                          backgroundColor: "#141414",
                          width: "60px",
                          borderRadius: "8px",
                          borderColor: "#313131",
                          outline: "none",
                        }}
                      />
                    </div>
                    <div className=" pt-2 ">
                      {message && <p className="text-red-500">{message}</p>}
                    </div>
                  </div>
                </div>
                <div className=" flex justify-center">
                  <span
                    onClick={() => {
                      setForgotPasswordStep(0);
                      setOtp("");
                    }}
                    className="text-primary w-min whitespace-nowrap hover:underline hover:font-medium cursor-pointer text-sm text-center font-medium"
                  >
                    Change Email
                  </span>
                </div>

                <span className="flex justify-center mt-2 py-2">
                  <ButtonDefault
                    size={4}
                    variant={ButtonVariants.WHITE}
                    className="p-1 w-full"
                    onClick={(e) => handleCheckOtp(e, Number(otp))}
                    disabled={loading}
                    loading={loading}

                    // onClick={() => router.push('/auth/login')}
                  >
                    <span className="flex text-sm font-bold gap-x-2 px-4 py-1 whitespace-nowrap">
                      Done
                    </span>
                  </ButtonDefault>
                </span>
              </ShouldRender>

              <ShouldRender check={forgotPasswordStep === 2}>
                <h1 className="text-xl text-fadedWhite flex gap-x-2 justify-center font-medium lg:font-normal lg:text-3xl mt-1 leading-10  text-center w-full">
                  Reset your
                  <b className="text-primary font-medium">password</b>
                </h1>
                <div className="flex justify-center mt-2">
                  <span className="text-textGray text-center text-lg w-[80%]">
                    Use at least 10 characters: uppercase, lowercase, and
                    numbers.
                  </span>
                </div>

                <form
                  onSubmit={submitPassword}
                  className="flex flex-col  gap-2 mt-5"
                >
                  <div className="col-span-1 flex-col flex gap-y-.5">
                    <Input.Password
                      name="password"
                      value={password.password}
                      placeholder="Password"
                      style={{
                        backgroundColor: "#141414",
                      }}
                      prefix={
                        <span className="pr-2">
                          <PasswordIcon />
                        </span>
                      }
                      onChange={handleChange}
                      size="large"
                      className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
                    />
                    {passwordErrors.password && (
                      <p className="text-red-500">{passwordErrors.password}</p>
                    )}
                  </div>
                  <div className="col-span-1 flex-col flex gap-y-.5">
                    <Input.Password
                      name="reEnterPassword"
                      placeholder="Re-enter Password"
                      value={password.reEnterPassword}
                      prefix={
                        <span className="pr-2">
                          <LockCheckIcon />
                        </span>
                      }
                      style={{
                        backgroundColor: "#141414",
                      }}
                      onChange={handleChange}
                      size="large"
                      className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
                    />
                    {passwordErrors.reEnterPassword && (
                      <p className="text-red-500">
                        {passwordErrors.reEnterPassword}
                      </p>
                    )}
                  </div>

                  <span className="flex justify-center mt-6">
                    <ButtonDefault
                      size={4}
                      variant={ButtonVariants.WHITE}
                      className="p-1 w-full"
                      disabled={loading}
                      loading={loading}
                    >
                      <span className="flex text-sm font-bold gap-x-2 px-4 py-1 whitespace-nowrap">
                        Done
                      </span>
                    </ButtonDefault>
                  </span>
                  {/* <span className='flex justify-center'>
                <span
                  onClick={() => {
                    if (onCancel) {
                      handleModalClose();
                    } else {
                      router.push('/auth/login');
                    }
                  }}
                  className='text-center  text-primary  whitespace-nowrap px-1 hover:font-bold underline cursor-pointer text-sm'
                >
                  GO BACK
                </span>
              </span> */}
                </form>
              </ShouldRender>
              <ShouldRender check={forgotPasswordStep === 3}>
                <h1 className="text-xl text-fadedWhite font-medium lg:font-normal lg:text-3xl mt-1 leading-10  text-center w-full">
                  Password <b className="text-primary font-medium">Reset</b>
                </h1>
                <span className="text-textGray text-center text-lg">
                  Your password is updated.
                </span>
              </ShouldRender>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ForgotPasswordModal;
