import { PhotographIcon } from "@heroicons/react/outline";
import { Upload } from "antd";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import ImgCrop from "antd-img-crop";
import { uploadImage } from "../../../services/landingPage";

const { Dragger } = Upload;

interface ImageUploaderProps {
  maxFileCount?: number;
  uploadAction: string;
  onChange: (file: string | null) => void;
  defaultImage?: string | null;
  clearImage?: boolean;
  accept?: string;
  updateText?: string;
  aspectRatio?: number;
  requiredWidth?: number;
  requiredHeight?: number;
}

const ImageUploadUrl: React.FC<ImageUploaderProps> = ({
  maxFileCount = 5,
  uploadAction,
  onChange,
  defaultImage,
  accept = ".jpg, .jpeg, .png, .webp",
  updateText = "Drag and drop files here or click to upload",
  aspectRatio = 1 / 1,
  requiredWidth,
  requiredHeight,
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    if (defaultImage) {
      setFileList([
        {
          uid: "-1",
          name: "defaultImage",
          status: "done",
          url: defaultImage,
        },
      ]);
    }
  }, [defaultImage]);

  const cropImage = (
    file: RcFile,
    aspectRatio: number
  ): Promise<Blob | null> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          let cropWidth, cropHeight, startX, startY;

          if (aspectRatio > 1) {
            cropWidth = img.width;
            cropHeight = img.width / aspectRatio;
            startX = 0;
            startY = (img.height - cropHeight) / 2;
          } else {
            cropWidth = img.height * aspectRatio;
            cropHeight = img.height;
            startX = (img.width - cropWidth) / 2;
            startY = 0;
          }

          canvas.width = cropWidth;
          canvas.height = cropHeight;

          if (ctx) {
            ctx.drawImage(
              img,
              startX,
              startY,
              cropWidth,
              cropHeight,
              0,
              0,
              cropWidth,
              cropHeight
            );
            canvas.toBlob((blob) => {
              resolve(blob);
            }, file.type);
          } else {
            resolve(null); // Fallback to null if context is not available
          }
        };
      };
    });
  };

  const handleChange: UploadProps["onChange"] = async ({
    fileList: newFileList,
  }) => {
    if (newFileList && newFileList.length > 0) {
      const firstFile = newFileList[0];

      if (firstFile && firstFile.originFileObj) {
        const imageUrl = URL.createObjectURL(firstFile.originFileObj as RcFile);
        const img = new Image();
        img.src = imageUrl;

        img.onload = async () => {
          const imageWidth = img.width;
          const imageHeight = img.height;
          const buffer = 10; // Buffer of 10px

          // Validate dimensions only if requiredWidth and requiredHeight are provided
          if (
            requiredWidth &&
            requiredHeight &&
            (Math.abs(imageWidth - requiredWidth) > buffer ||
              Math.abs(imageHeight - requiredHeight) > buffer)
          ) {
            console.error(
              "Uploaded image dimensions do not match the required size."
            );
            alert(
              `Image dimensions should be approximately ${requiredWidth}px x ${requiredHeight}px. Please upload an image with correct dimensions.`
            );
            setFileList([]);
            onChange(null);
            return;
          }

          // Proceed with cropping and uploading the image if dimensions are valid
          try {
            const croppedBlob = await cropImage(
              firstFile.originFileObj as RcFile,
              aspectRatio
            );
            if (croppedBlob) {
              const formData = new FormData();
              const croppedFile = new File([croppedBlob], firstFile.name, {
                type: firstFile.type,
              });
              formData.append("file", croppedFile);

              const response = await uploadImage(formData);

              if (response?.data) {
                setFileList([
                  {
                    uid: firstFile.uid,
                    name: firstFile.name,
                    status: "done",
                    url: `${response?.data?.data?.imageUrl}`,
                  },
                ]);
                onChange(response?.data?.data?.imageUrl);
              } else {
                onChange(null);
              }
            } else {
              onChange(null);
            }
          } catch (error) {
            console.error("Upload error:", error);
            onChange(null);
          }
        };
      } else {
        onChange(null);
      }
    } else {
      setFileList([]);
      onChange(null);
    }
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src && file.originFileObj) {
      src = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    if (typeof src !== "string") {
      console.error("File URL is not a string:", src);
      return;
    }

    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    if (imgWindow) {
      imgWindow.document.write(image.outerHTML);
    } else {
      console.error("Failed to open preview window");
    }
  };

  return (
    <ImgCrop aspect={aspectRatio}>
      <Dragger
        action={uploadAction}
        listType="picture-card"
        accept={accept}
        className={clsx(
          fileList.length ? "hideDragAndDrop" : "",
          "uploadToolTipDisable group"
        )}
        fileList={fileList}
        onChange={handleChange}
        onPreview={onPreview}
      >
        {fileList.length < maxFileCount && (
          <div className="group-hover:text-primary">
            <span className="flex justify-center">
              <PhotographIcon className="w-10 text-textGray" />
            </span>
            <div style={{ marginTop: 8 }} className="text-textGray">
              {updateText}
            </div>
          </div>
        )}
      </Dragger>
    </ImgCrop>
  );
};

export default ImageUploadUrl;
