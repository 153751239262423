import axios, { AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
import { checkSessionExists, setTokenInCookies } from "../config/appwrite";

const defaultMode = Cookies.get("working_mode");

const baseUrl =
  defaultMode === "live"
    ? process.env.REACT_APP_BASE_URL_ECOM_LIVE
    : process.env.REACT_APP_BASE_URL_ECOM;

const apiClientEcom = axios.create({
  baseURL: baseUrl,
  timeout: 30000,
});

apiClientEcom.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const authToken = Cookies.get("auth_token");
  config.headers = config.headers || {};

  if (authToken) {
    config.headers["Authorization"] = `Bearer ${authToken}`;
  } else {
    const session = await checkSessionExists();
    if (session) {
      const token = await setTokenInCookies();
      config.headers["Authorization"] = `Bearer ${token}`;
    }
  }
  return config;
});

apiClientEcom.interceptors.response.use((response: { data: null }) => {
  return response;
});

export default apiClientEcom;
