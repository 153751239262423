import React from "react";
import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
import clsx from "clsx";
import Parser from "../../shared/Parser";
import { useNavigate } from "react-router-dom";

interface IProductCard {
  data: {
    image: string;
    title: number;
    id: number;
    desc?: string;
    externalLink?: string;
    slug?: string;
  };
  isCourse?: boolean;

  setVisibleBook: React.Dispatch<
    React.SetStateAction<{ type: string; value: boolean; bookId?: number }>
  >;
}

const ProductCard: React.FC<IProductCard> = ({
  data,
  isCourse = false,
  setVisibleBook,
}) => {
  const { image, title, desc } = data;
  const router = useNavigate();

  return (
    <div className="flex flex-col md:flex-row justify-between border-b gap-x-5 border-b-bgLightGray">
      <span className="flex gap-x-4 py-5 w-full">
        <img
          src={image}
          className={clsx(
            !isCourse ? "w-28 h-36 rounded-sm" : "w-52 h-28 rounded-lg"
          )}
        />
        <div className="flex gap-y-2  w-full">
          <span className="lg:py-6  w-full">
            <p className="font-semibold text-xl">{title}</p>
            <p className=" lg:w-1/2 text-textDarkGrayColor  ">
              <Parser
                maxWords={20}
                text={desc?.replace(/<[^>]*>?/gm, "") || ""}
              />
            </p>
          </span>
        </div>
      </span>
      <span className="flex flex-col gap-y-2 items-center justify-center">
        <ButtonDefault
          size={2}
          variant={ButtonVariants.WHITE}
          className="p-1 w-full"
          onClick={() => {
            router(`/products/${data?.id}`);
          }}
        >
          <span rel="noreferrer" className="px-5 whitespace-nowrap">
            Chapters
          </span>
        </ButtonDefault>

        <ButtonDefault
          size={2}
          variant={ButtonVariants.GRAY}
          onClick={() =>
            setVisibleBook({ type: "Edit", value: true, bookId: data.id })
          }
          className="p-1 w-full"
        >
          {" "}
          <span className="px-5 whitespace-nowrap text-white hover:text-gray-200">
            Edit Book
          </span>
        </ButtonDefault>
      </span>
    </div>
  );
};

export default ProductCard;
