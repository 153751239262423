import { InboxOutlined, FileTextOutlined } from "@ant-design/icons";
import { TrashIcon } from "@heroicons/react/outline";
import { Image, message } from "antd";

import React, { DragEvent, useEffect, useRef, useState } from "react";
import { ShouldRender } from "../../shared/basic/ShouldRender";

interface FileUploadProps {
  image?: string | null;
  placeholder: string;
  accept?: string;
  fileKey?: number;
  onFileChange: (file: File) => void;
  onImageRemove: () => void;
  isSession?: boolean;
}

const UploadFile: React.FC<FileUploadProps> = ({
  fileKey,
  onFileChange,
  onImageRemove,
  image,
  // isSession = false,
  placeholder,
  accept = ".jpg, .jpeg",
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imageFile, setImageFile] = useState<any>(image);
  // const [loaded, setLoaded] = useState(false);

  const handleFileChange = (selectedFile: File) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      if (event.target) {
        const imageDataUrl = event.target.result as string;
        onFileChange(selectedFile);
        if (selectedFile) {
          setImageFile(accept === ".pdf" ? selectedFile?.name : imageDataUrl);
        }
        message.success(`${selectedFile?.name} file uploaded successfully.`);
      }
    };

    // Ensure selectedFile.type is available, otherwise use a default MIME type
    const mimeType = selectedFile?.type || "application/octet-stream";

    // Convert selectedFile to Blob
    const blob = new Blob([selectedFile], { type: mimeType });
    reader.readAsDataURL(blob);
  };
  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleRemoveFile = () => {
    setImageFile(undefined);
    onImageRemove();
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const selectedFile = e.dataTransfer.files[0];

    if (selectedFile) {
      handleFileChange(selectedFile);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    if (typeof image === "string") {
      setImageFile(image);
    }
  }, [image]);

  return (
    <div
      className="flex justify-center items-center cursor-pointer group text-gray-700 transition-all border-[#424242] duration-400 ease-in-out hover:text-primary bg-[#080808] hover:border-primary border-2 p-5 border-dashed rounded-lg"
      onClick={handleButtonClick}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {!imageFile ? (
        <label className="cursor-pointer ">
          <p className="ant-upload-drag-icon flex justify-center cursor-pointer">
            <InboxOutlined className="text-5xl text-textDarkGrayColor group-hover:text-primary" />
          </p>
          <p className="ant-upload-text text-textDarkGrayColor text-center cursor-pointer text-textLiteGray group-hover:text-primary">
            {placeholder}
          </p>
        </label>
      ) : (
        <ShouldRender check={imageFile !== undefined}>
          <div className="flex flex-row-reverse items-start gap-x-2">
            {imageFile && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleRemoveFile();
                }}
                className="flex justify-end hover:text-red-700"
              >
                <TrashIcon className="w-4" />
              </div>
            )}

            <div className="flex flex-col gap-y-4 justify-center items-center ">
              <ShouldRender check={accept !== ".pdf"}>
                {imageFile && (
                  <div className="flex items-center w-[150px] h-[100px]">
                    <Image
                      src={imageFile}
                      width={200}
                      height={100}
                      className=" "
                      alt="_Image"
                    />
                  </div>
                )}
              </ShouldRender>
              <ShouldRender check={accept === ".pdf"}>
                <div className="flex flex-col items-center gap-y-2 w-[150px] h-[100px] justify-center">
                  <FileTextOutlined className="text-5xl text-textDarkGrayColor" />
                  <span className="flex justify-center text-center text-textDarkGrayColor font-medium">
                    {imageFile}
                  </span>
                </div>
              </ShouldRender>
            </div>
          </div>
        </ShouldRender>
      )}

      <input
        key={fileKey}
        type="file"
        id="fileInput"
        ref={fileInputRef}
        className="cursor-pointer"
        accept={accept}
        style={{ display: "none" }}
        onChange={(e) =>
          e.target.files && handleFileChange(e.target.files[0] as File)
        }
      />
    </div>
  );
};

export default UploadFile;
