import { atom } from "recoil";

export const authModalControl = atom<{
  login: boolean;
  signUp: boolean;
  forgotPassword: boolean;
  bookId: string;
}>({
  key: "authModalControl",
  default: { login: false, signUp: false, forgotPassword: false, bookId: "" },
});

export const tokenUpdate = atom<boolean>({
  key: "tokenUpdate",
  default: false,
});
