import React, { useEffect, useState } from "react";

import { Button, Empty, Table } from "antd";
import { useParams } from "react-router-dom";

import { getAllChapters } from "../../../services/dashboard";
import extractErrorMessage from "../../../components/shared/basic/formateError";
import { notify } from "../../../components/shared/basic/notify";
import { IChapters } from "../../../utils/interfaces/Chapters";
import { PlusOutlined } from "@ant-design/icons";
import ChapterModal from "../../../components/Chapter/ChapterModal";

const ChapterPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IChapters[]>();
  const [visibleChapter, setVisibleChapter] = useState<{
    type: string;
    value: boolean;
    chapterId?: number;
  }>({ type: "", value: false });
  const params = useParams();

  const onCancelChapterModal = () => {
    setVisibleChapter({ type: "", value: false });
    if (params.bookId) {
      handleChaptersList(params.bookId as string);
    }
  };

  const handleChaptersList = async (bookId: string) => {
    try {
      setLoading(true);
      const response = await getAllChapters(bookId);
      setData(
        response?.data?.chapters?.map((chapter: any) => {
          return {
            id: chapter?._id,
            bookId: chapter?.book,
            chapterNumber: chapter?.chapterNumber,
            name: chapter?.name,
            startPage: chapter?.startPage,
            endPage: chapter?.endPage,
          };
        })
      );
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params.bookId) {
      handleChaptersList(params.bookId as string);
    }
  }, [params.bookId]);

  const columns = [
    {
      title: (
        <span className="text-xs flex py-2 justify-start md:pl-10">
          Chapter Number
        </span>
      ),
      dataIndex: "chapterNumber",
      key: "chapterNumber",
      render: (text: number) => {
        return (
          <div className="flex gap-x-4 items-center font-medium md:pl-10 justify-start hover:cursor-pointer hover:underline text-center">
            #{text}
          </div>
        );
      },
    },
    {
      title: <span className="text-xs flex py-2 justify-center ">Name</span>,
      dataIndex: "name",
      key: "name",
      render: (text: string) => {
        return (
          <div className="flex gap-x-4 items-center font-medium justify-center hover:cursor-pointer hover:underline text-center">
            {text}
          </div>
        );
      },
    },
    {
      title: <span className="text-xs flex  justify-center">Book Id</span>,
      dataIndex: "bookId",
      key: "bookId",
      render: (text: string) => {
        return (
          <div className="flex font-medium text-center justify-center ">
            {text}
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs flex justify-center text-center">
          Start Page
        </span>
      ),
      dataIndex: "startPage",
      key: "startPage",
      render: (text: string) => {
        return <div className="flex font-medium justify-center ">{text}</div>;
      },
    },
    {
      title: (
        <span className="text-xs flex justify-center text-center">
          End Page
        </span>
      ),
      dataIndex: "endPage",
      key: "endPage",
      render: (text: string) => {
        return <div className="flex font-medium justify-center ">{text}</div>;
      },
    },
    {
      title: (
        <span className="text-xs flex justify-center text-center">Edit</span>
      ),

      key: "Edit_action",
      render: (record: any) => {
        return (
          <div className="flex font-medium justify-center ">
            <Button
              onClick={() =>
                setVisibleChapter({
                  type: "Edit",
                  value: true,
                  chapterId: record.id,
                })
              }
            >
              Edit
            </Button>
          </div>
        );
      },
    },
  ];

  const rowClassName = (_: any, index: number) => {
    return index % 2 === 0 ? "bg-[#131313]" : "bg-bgColor";
  };

  return (
    <div className="bg-readerLightGrayBg rounded-xl ">
      <div className="py-4 px-6 flex flex-col justify-between md:flex-row gap-x-2 items-center">
        <p className="font-semibold flex justify-start text-start text-base lg:text-lg whitespace-nowrap">
          All Chapters{" "}
        </p>
        <span>
          <Button
            onClick={() => setVisibleChapter({ type: "Create", value: true })}
            icon={<PlusOutlined />}
          >
            Create Chapter
          </Button>
        </span>
      </div>

      <div className="customScrollBar ">
        <Table
          dataSource={data}
          loading={loading}
          className={`tableHeader  rounded-none customTable overflow-auto customScrollBar`}
          columns={columns}
          pagination={false}
          rowClassName={rowClassName}
          locale={{
            emptyText: (
              <span>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>No Chapters</span>}
                  className={`h-40 flex justify-center items-center flex-col`}
                />
              </span>
            ),
          }}
        />
      </div>
      <ChapterModal
        type={visibleChapter.type}
        chapterId={visibleChapter.chapterId as number}
        onCancel={onCancelChapterModal}
        visible={visibleChapter.value as boolean}
      />
    </div>
  );
};

export default ChapterPage;
