import { Dictionary, isEmpty } from "lodash";
import { useState } from "react";

import { notify } from "../../components/shared/basic/notify";
import {
  formatErrorObject,
  removeAdminRole,
  removeAuth,
  setToken,
} from "../../utils/lib/auth";

import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useRecoilState } from "recoil";
import { tokenUpdate } from "../../atoms/toggleTheme/authModalControl";
import appwriteService, { setTokenInCookies } from "../../config/appwrite";
import { AppwriteUserData, UserData } from "../../utils/interfaces/user";
import apiClientEcom from "../../services/ecomBase";
import { Endpoints } from "../../network";

export const useLogin = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [, setIsTokenChanged] = useRecoilState(tokenUpdate);

  const router = useNavigate();

  // const handleAssignAffiliate = async () => {
  //   try {
  //     await apiClient.get(Endpoints.affiliateCheck);
  //   } catch (error) {
  //     const message = "Something went wrong, please try again.";

  //     notify(message, "error");
  //   }
  // };

  const onLogin = async (
    email: string,
    password: string
  ): Promise<Dictionary<string | JSX.Element>> => {
    setLoading(true);
    const errorMessages: Dictionary<JSX.Element | string> = {};

    let notificationSent = false; // Flag to track if notification has been sent

    try {
      // generic login call

      await appwriteService.logoutIfSessionExists();
      const response = await appwriteService.login({
        email,
        password,
      });

      const token = await setTokenInCookies();
      let currentUser: AppwriteUserData | undefined = undefined;

      if (response) {
        const user = await appwriteService.getCurrentUser();
        if (user !== null) {
          currentUser = user as unknown as AppwriteUserData;
        }
      }
      const dbUser: UserData = await appWriteUser(currentUser?.$id as string);

      await setToken(token);
      setIsTokenChanged(true);

      if (isEmpty(dbUser)) {
        throw Error();
      }

      // await handleAssignAffiliate();

      // setUserData(data?.data?.user);

      if (isEmpty(dbUser)) {
        throw Error();
      } else {
        Cookies.set("user_id", dbUser.id as string);

        if (!notificationSent) {
          notify("Successfully Logged IN!", "success");
          notificationSent = true;
        }
      }
      router("/products");
    } catch (error) {
      const message = "Something went wrong, please try again.";

      if (!notificationSent) {
        notify(message, "error");
        notificationSent = true;
      }
    } finally {
      setLoading(false);
    }
    return errorMessages;
  };

  const onLogOut = async (): Promise<Dictionary<string | JSX.Element>> => {
    const errorMessages: Dictionary<JSX.Element | string> = {};

    removeAuth();
    removeAdminRole();

    try {
      await appwriteService.logout();
      setIsTokenChanged(true);
      router("/");
      notify("Logged out successfully", "success");
    } catch (error: any) {
      const message = formatErrorObject(error);

      console.log(message);
    } finally {
      setLoading(false);
    }
    return errorMessages;
  };

  return { loading, onLogin, onLogOut };
};

export const appWriteUser = async (zl_uid: string) => {
  try {
    const response = await apiClientEcom.post(Endpoints.appWriteUser, {
      zl_uid,
    });
    return response?.data?.data;
  } catch (error: any) {
    const message = formatErrorObject(error);

    console.log(message);
  }
};
