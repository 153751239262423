import { Endpoints } from "../../network";
import apiClient from "../base";
import apiClientEcom from "../ecomBase";

interface IProductList {
  category_id?: number;
  page?: number;
  start_date?: string;
  end_date?: string;
  book_type?: string;
  limit?: number;
  name?: string;
  isListed?: boolean;
}

export interface IBook {
  title: string;
  authors: string;
  author_list: string[];
  isListed: boolean;
  productId: number;
  hardcoverProductId: number;
  digitalProductId: number;
  slug: string;
  isExternalLink: boolean;
  description: string;
  type: string;
  longDescription: string;
  totalPages: number | undefined;
  externalLink: string;
  coverImage: any;
  samplePdf?: any;
  categories: string[];
}

export interface IChapter {
  name: string;
  chapterNumber: number | undefined;
  totalPages: number | undefined;
  startPage: number | undefined;
  endPage: number | undefined;
  description: string;
  chapterFile: any;
  bookId: string;
}

export const getProductsList = async (data: IProductList) => {
  const { page = 1, name, book_type, limit = 6, isListed } = data;
  let url = Endpoints.getProductsList;

  const queryParams = [];

  if (page !== undefined && page !== null && page !== 0) {
    queryParams.push(`page=${page}`);
  }
  if (limit) {
    queryParams.push(`limit=${limit}`);
  }

  if (book_type) {
    queryParams.push(`type=${book_type}`);
  }

  if (name) {
    queryParams.push(`title=${name}`);
  }

  queryParams.push(`isListed=${isListed}`);

  if (queryParams.length > 0) {
    url += `?${queryParams.join("&")}`;
  }

  const response = await apiClient.get(url);

  return response?.data;
};

export const getShortBooksList = async (data: IProductList) => {
  const { page = 1, name } = data;
  let url = Endpoints.getShortBook;

  const queryParams = [];

  if (page !== undefined && page !== null && page !== 0) {
    queryParams.push(`page=${page}`);
  }

  queryParams.push(`limit=6`);

  if (name) {
    queryParams.push(`title=${name}`);
  }

  if (queryParams.length > 0) {
    url += `?${queryParams.join("&")}`;
  }

  const response = await apiClient.get(url);

  return response?.data;
};

export const getAllChapters = async (bookId: string) => {
  const response = await apiClient.get(
    `${Endpoints.getAllChapters}?bookId=${bookId}`
  );

  return response?.data;
};

export const getSingleBook = async (id: string) => {
  const response = await apiClient.get(`${Endpoints.getProductsList}/${id}`);

  return response?.data;
};

export const getSingleChapter = async (id: string) => {
  const response = await apiClient.get(`${Endpoints.getAllChapters}/${id}`);

  return response?.data;
};

export const getAllProducts = async (data: IProductList) => {
  const { category_id, book_type } = data;
  let url = Endpoints.getAllProducts;

  const queryParams = [];
  if (category_id) {
    queryParams.push(`category_id=${category_id}`);
  }
  if (book_type) {
    queryParams.push(`book_type=${book_type}`);
  }
  queryParams.push(`perPage=${100}`);

  if (queryParams.length > 0) {
    url += `?${queryParams.join("&")}`;
  }

  const response = await apiClientEcom.get(url);

  return response?.data;
};

export const getAllAuthors = async () => {
  const response = await apiClient.get(Endpoints.getAllAuthors);

  return response?.data;
};

export const getSingleAuthors = async (authorId: string) => {
  const response = await apiClient.get(
    `${Endpoints.getAllAuthors}/${authorId}`
  );

  return response?.data;
};

export const deleteSingleAuthors = async (authorId: string) => {
  const response = await apiClient.delete(
    `${Endpoints.getAllAuthors}/${authorId}`
  );

  return response?.data;
};

export const getAllCategories = async () => {
  const response = await apiClient.get(Endpoints.getAllCategories);

  return response?.data;
};

export const deleteCategory = async (categoryId: string) => {
  const response = await apiClient.delete(
    `${Endpoints.getAllCategories}/${categoryId}`
  );

  return response?.data;
};

export const getSingleCategories = async (categoryId: string) => {
  const response = await apiClient.get(
    `${Endpoints.getAllCategories}/${categoryId}`
  );

  return response?.data;
};

export const addCategories = async ({ name }: { name: string }) => {
  const response = await apiClient.post(Endpoints.getAllCategories, { name });

  return response?.data;
};

export const updateCategories = async (
  {
    name,
  }: {
    name: string;
  },
  categoryId: string
) => {
  const response = await apiClient.put(
    `${Endpoints.getAllCategories}/${categoryId}`,
    { name }
  );

  return response?.data;
};

export const createBook = async (data: IBook) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    const propertyKey = key as keyof IBook;

    if (propertyKey in data) {
      const value = data[propertyKey];

      if (Array.isArray(value)) {
        value.forEach((val) => {
          formData.append(`${propertyKey}[]`, val);
        });
      } else {
        formData.append(propertyKey, value);
      }
    }
  });

  const response = await apiClient.post(Endpoints.createBook, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response?.data;
};

export const editBook = async (bookId: string, data: IBook) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    const propertyKey = key as keyof IBook;
    const value = data[propertyKey];

    if (value !== undefined) {
      if (Array.isArray(value)) {
        value.forEach((val) => {
          formData.append(`${propertyKey}[]`, val);
        });
      } else {
        formData.append(propertyKey, value);
      }
    }
  });

  const response = await apiClient.put(
    `${Endpoints.createBook}/${bookId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response?.data;
};
export const createChapter = async (data: IChapter) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    const propertyKey = key as keyof IChapter;

    if (propertyKey in data) {
      const value = data[propertyKey];

      // If it's an array, handle it accordingly
      if (Array.isArray(value)) {
        value.forEach((val) => {
          formData.append(`${propertyKey}[]`, val);
        });
      } else {
        formData.append(propertyKey, value);
      }
    }
  });
  const response = await apiClient.post(Endpoints.getAllChapters, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response?.data;
};
export const editChapter = async (chapterId: string, data: IChapter) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    const propertyKey = key as keyof IChapter;
    const value = data[propertyKey];

    // Check if the value is not undefined
    if (value !== undefined) {
      // If it's an array, handle it accordingly
      if (Array.isArray(value) && value.length > 0) {
        value.forEach((val) => {
          formData.append(`${propertyKey}[]`, val);
        });
      } else {
        formData.append(propertyKey, value);
      }
    }
  });

  const response = await apiClient.put(
    `${Endpoints.getAllChapters}/${chapterId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response?.data;
};

export const createAuthor = async (data: {
  name: string;
  designation: string[];
  avatar: any;
  description: string[];
  longDescription: string;
  email: string;
}) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    const propertyKey = key as keyof {
      name: string;
      designation: string[];
      avatar: any;
      description: string[];
      longDescription: string;
      email: string;
    };

    if (propertyKey in data) {
      const value = data[propertyKey];

      // If it's an array, handle it accordingly
      if (Array.isArray(value)) {
        value.forEach((val) => {
          formData.append(`${propertyKey}[]`, val);
        });
      } else {
        formData.append(propertyKey, value);
      }
    }
  });
  const response = await apiClient.post(`${Endpoints.createAuthor}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response?.data;
};

export const editAuthor = async (
  authorId: string,
  data: {
    name: string;
    designation: string[];
    avatar: any;
    description: string[];
    longDescription: string;
    email: string;
  }
) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    const propertyKey = key as keyof {
      name: string;
      designation: string[];
      avatar: any;
      description: string[];
      longDescription: string;
      email: string;
    };

    if (propertyKey in data) {
      const value = data[propertyKey];

      // If it's an array, handle it accordingly
      if (propertyKey === "avatar") {
        // Check if 'avatar' is a string, if so, append it directly
        if (typeof value !== "string") {
          formData.append(propertyKey, value);
        } else if (Array.isArray(value)) {
          // If it's an array, append only the first element
          formData.append(propertyKey, value[0]);
        }
      } else if (Array.isArray(value)) {
        // If it's an array, handle it accordingly
        value.forEach((val) => {
          formData.append(`${propertyKey}[]`, val);
        });
      } else {
        formData.append(propertyKey, value);
      }
    }
  });
  const response = await apiClient.put(
    `${Endpoints.createAuthor}/${authorId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response?.data;
};
