import { Button, Drawer, Layout, Menu, SelectProps } from "antd";

import React, { ReactNode, useEffect, useState } from "react";

import { LogoWhite, WhiteLogoIcon } from "../../assets";
import {
  LogoutIcon,
  MenuIcon,
  PhotographIcon,
  XIcon,
} from "@heroicons/react/outline";

import {
  useNavigate,
  Link,
  Outlet,
  useLocation,
  NavLink,
  useParams,
} from "react-router-dom";
import { Header } from "antd/es/layout/layout";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { ShouldRender } from "../shared/basic/ShouldRender";
import ProductsIcon from "../../assets/Icons/ProductsIcon";
import clsx from "clsx";
import PersonIcon from "../../assets/Icons/PersonIcon";
import Cookies from "js-cookie";
import CategoryIcon from "../../assets/Icons/CategoryIcon";
import { useLogin } from "../../hooks/useAuth/useLogin";

const { Sider, Content } = Layout;

interface DefaultLayoutProps {
  children: ReactNode;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children }) => {
  const router = useNavigate();
  const location = useLocation();
  const defaultMode = Cookies.get("working_mode");
  const { landingPageId } = useParams();

  const isDashboardRoute = (route: string) => {
    return route.startsWith("/dashboard") && route.match(/\/dashboard\/\d+/);
  };
  // const zebraLearnBaseUrl = process.env.REACT_APP_ZEBRALEARN_BASE_URL;

  const isPaymentHistoryDetails = (route: string) => {
    return route.startsWith("/dashboard/paymentHistory/");
  };

  const [drawerVisible, setDrawerVisible] = useState(false);

  const [scrolled, setScrolled] = useState(false);
  const { onLogOut } = useLogin();
  const screens = useBreakpoint();
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const routePath = location?.pathname ?? "";
  // const adminRoleIds = [1];

  const getActiveMenuItem = (routePath: string) => {
    if (routePath.startsWith("/products")) {
      return "1";
    } else if (routePath.startsWith("/authors")) {
      return "2";
    } else if (routePath.startsWith("/categories")) {
      return "3";
    } else if (routePath.startsWith("/bannerPages")) {
      return "4";
    } else if (routePath.startsWith("/book-landing-page")) {
      return "5";
    }

    return "1";
  };

  const getActiveHeaderName = (routePath: string) => {
    if (routePath.startsWith("/dashboard")) {
      return "Dashboard";
    } else if (routePath.startsWith("/authors")) {
      return "Authors";
    } else if (routePath.startsWith("/products")) {
      return "Products";
    } else if (routePath.startsWith("/categories")) {
      return "Categories";
    } else if (routePath.startsWith("/bannerPages")) {
      return "Banner Pages";
    } else if (routePath.startsWith("/book-landing-page")) {
      return "Book Landing Page";
    }

    return "Dashboard";
  };

  const selectedKey = getActiveMenuItem(routePath);

  const activeHeaderName = getActiveHeaderName(routePath);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  // const getUserDetails = async () => {
  //   try {
  //     const data = await getUser();
  //     const isAdmin =
  //       data?.data?.data?.roles &&
  //       data?.data?.data?.roles.some((role: any) =>
  //         adminRoleIds.includes(role.id)
  //       );

  //     setIsAdmin(isAdmin);

  //     await setCode(data?.data?.data?.affiliate_user?.code);
  //     setUserData({
  //       phone: data?.data?.data?.phone,
  //       name: data?.data?.data?.name,
  //       email: data?.data?.data?.email,
  //       gender: data?.data?.data?.email,
  //       password: data?.data?.data?.password,
  //       role_id: data?.data?.data?.roles?.map((role: any) => {
  //         return {
  //           role: role?.id,
  //         };
  //       }),
  //       phoneNumber: data?.data?.data?.phone,
  //       role: data?.data?.data?.roles?.map((role: any) => {
  //         return {
  //           id: role?.id,
  //           name: role?.name,
  //         };
  //       }),
  //       is_new_user: data?.data?.data?.affiliate_user?.is_new_user,
  //     });
  //   } catch (error: any) {
  //     notify("You are not authenticated", "error");
  //     router(`/?redirectUrl=${location.pathname}`);
  //   }
  // };

  // useEffect(() => {
  //   getUserDetails();
  // }, [router]);

  const options: SelectProps["options"] = [];

  for (let i = 10; i < 36; i++) {
    options.push({
      value: i?.toString(36) + i,
      label: i?.toString(36) + i,
    });
  }

  // const onRangeChange = (
  //   dates: null | (Dayjs | null)[],
  //   dateStrings: string[]
  // ) => {
  //   // if (dates) {
  //   //   setDateFilter({
  //   //     start_date: dateStrings[0] as string,
  //   //     end_date: dateStrings[1] as string,
  //   //   });
  //   // } else {
  //   //   setDateFilter({
  //   //     start_date: "" as string,
  //   //     end_date: "" as string,
  //   //   });
  //   // }
  // };

  const sidebarContent = (
    <div className="flex flex-col h-full justify-between">
      <div>
        <div className="demo-logo-vertical" />
        <span
          className={clsx(
            screens?.md
              ? "justify-center"
              : "justify-between items-center w-full",
            " flex  px-7 pt-10   pb-5"
          )}
        >
          <img
            src={!screens?.md ? LogoWhite : WhiteLogoIcon}
            alt="Logo"
            className={clsx(
              screens?.md ? "h-6" : "h-4",
              "object-contain  w-fit cursor-pointer"
            )}
            onClick={() => router("/products")}
          />
          <ShouldRender check={!screens.md}>
            <span
              onClick={() => setDrawerVisible(false)}
              className=" cursor-pointer group flex justify-center items-center rounded-full "
            >
              <XIcon className="w-6 h-6 group-hover:text-textGrayColor text-[#9A9A9A]" />
            </span>
          </ShouldRender>
        </span>
        <Menu
          style={{
            border: "none",
          }}
          className="mt-4 px-2.5 bg-bgColor border-none customMenuItems"
          mode="inline"
          selectedKeys={[selectedKey]}
        >
          <Menu.Item
            key="1"
            onClick={() => setDrawerVisible(false)}
            icon={
              <span className="md:pt-2 -translate-x-1">
                <ProductsIcon
                  outlined={selectedKey !== "1"}
                  fill={selectedKey !== "1" ? "#737373" : "white"}
                />
              </span>
            }
          >
            <Link to="/products">Products</Link>
            <div className="indicator" />
          </Menu.Item>

          <Outlet />

          <Menu.Item
            key="2"
            className="flex items-center justify-center"
            onClick={() => setDrawerVisible(false)}
            icon={
              <span className="md:pt-2 translate-x-5 md:-translate-x-1">
                <PersonIcon
                  outlined={selectedKey !== "2"}
                  fill={selectedKey !== "2" ? "#737373" : "white"}
                />
              </span>
            }
          >
            <Link className="pl-6 md:pl-0" to="/authors">
              Authors
            </Link>
            <div className="indicator" />
          </Menu.Item>
          <Menu.Item
            key="3"
            className="flex items-center justify-center"
            onClick={() => setDrawerVisible(false)}
            icon={
              <span className="md:pt-2 translate-x-5 md:-translate-x-1">
                <CategoryIcon
                  outlined={selectedKey !== "3"}
                  fill={selectedKey !== "3" ? "#737373" : "white"}
                />
              </span>
            }
          >
            <Link className="pl-6 md:pl-0" to="/categories">
              Categories
            </Link>
            <div className="indicator" />
          </Menu.Item>
          <Menu.Item
            key="4"
            className="flex items-center justify-center"
            onClick={() => setDrawerVisible(false)}
            icon={
              <span className="md:pt-2 translate-x-5 w-7 md:-translate-x-2">
                <PhotographIcon
                  className={
                    selectedKey !== "4" ? "text-[#737373]" : "text-white"
                  }
                />
              </span>
            }
          >
            <NavLink className="pl-6 md:pl-0" to="/bannerPages">
              Banner Pages
            </NavLink>
            <div className="indicator" />
          </Menu.Item>
          <Menu.Item
            key="5"
            className="flex items-center justify-center"
            onClick={() => setDrawerVisible(false)}
            icon={
              <span className="md:pt-2 translate-x-5 w-7 md:-translate-x-2">
                <PhotographIcon
                  className={
                    selectedKey !== "5" ? "text-[#737373]" : "text-white"
                  }
                />
              </span>
            }
          >
            <NavLink className="pl-6 md:pl-0" to="/book-landing-page">
              Book Landing Page
            </NavLink>
            <div className="indicator" />
          </Menu.Item>
        </Menu>
      </div>

      <div className="flex w-full justify-between px-5 pb-7 ">
        <Button
          icon={<LogoutIcon className="w-5" />}
          className=" w-full flex justify-center gap-x-3 h-10 items-center shadow-sm rounded-md "
          onClick={() => {
            onLogOut();
          }}
        >
          <ShouldRender check={!screens?.md}>Logout</ShouldRender>
        </Button>
      </div>
    </div>
  );

  return (
    <Layout>
      {screens.md ? (
        <Sider
          trigger={null}
          collapsible
          collapsed={true}
          width={200}
          className="site-layout-background border-r-2 border-[#222222] z-40"
          style={{
            position: "fixed",
            height: "100vh",
            left: 0,
            zIndex: 1,
            backgroundColor: "#080808",
          }}
        >
          {sidebarContent}
        </Sider>
      ) : (
        <Drawer
          className="drawerPaddingNone overflow-hidden"
          width={450}
          placement="right"
          closable={false}
          onClose={() => setDrawerVisible(!drawerVisible)}
          open={drawerVisible}
          key="left"
        >
          {sidebarContent}
        </Drawer>
      )}

      <Layout
        style={{
          marginLeft: screens.md ? 80 : 0,
          transition: "margin-left 0.2s",
        }}
      >
        <ShouldRender check={!scrolled}>
          <div
            className={`${
              defaultMode !== "live" ? "bg-yellow-500" : "bg-green-500"
            } w-full text-white font-medium  pl-5 py-1`}
          >
            {defaultMode === "live" ? "Live Mode" : "Staging Mode"}
          </div>
        </ShouldRender>

        <ShouldRender
          check={
            !isDashboardRoute(location?.pathname) &&
            !isPaymentHistoryDetails(location?.pathname) &&
            !landingPageId &&
            !location.pathname.includes("/book-landing-page/create")
          }
        >
          <Header
            className={`z-40 flex flex-col justify-between items-end ${
              scrolled ? "scrolled" : ""
            }`}
            style={{
              padding: 0,
              background: "#080808",
              position: "sticky",
              width: "100%",
              height: scrolled ? "80px" : screens.md ? "40px" : "50px",
              alignItems: scrolled ? "center" : "end",
              top: 0,
              transition: "height 0.3s ease, align-items 1s ease",
            }}
          >
            <div
              className={clsx(
                activeHeaderName === "Dashboard" && "justify-between",
                !screens.md && "justify-between",
                "flex flex-col w-full  items-end gap-x-5 "
              )}
            >
              <ShouldRender check={scrolled}>
                <div
                  className={`${
                    defaultMode !== "live" ? "bg-yellow-500" : "bg-green-500"
                  } w-full text-white font-medium  pl-5 py-1 h-7 flex items-center`}
                >
                  {defaultMode === "live" ? "Live Mode" : "Staging Mode"}
                </div>
              </ShouldRender>
              <div
                className={clsx(
                  activeHeaderName === "Dashboard" && "justify-between",
                  !screens.md && "justify-between",
                  "flex flex-col w-full px-4 md:pr-10 pt-2 items-end gap-x-5 "
                )}
              >
                <span className=" flex justify-between w-full  items-center  gap-x-4  ">
                  <ShouldRender check={!screens.md}>
                    <img
                      src={WhiteLogoIcon}
                      alt="Logo"
                      onClick={() => router("/dashboard", { replace: true })}
                      className="object-contain h-6 w-full"
                    />
                  </ShouldRender>

                  <p className="text-xl md:text-2xl font-medium text-fadedWhite md:pl-6 whitespace-nowrap">
                    {activeHeaderName}
                  </p>
                </span>

                <ShouldRender check={!screens.md}>
                  <span
                    onClick={() => showDrawer()}
                    className="flex items-center w-6 h-6 hover:cursor-pointer "
                  >
                    {drawerVisible ? (
                      <XIcon className="w-full" />
                    ) : (
                      <MenuIcon className="w-full" />
                    )}
                  </span>
                </ShouldRender>
              </div>
            </div>
          </Header>
        </ShouldRender>
        <Content
          className={clsx({
            "px-4 md:px-10 pb-10":
              !landingPageId &&
              !location.pathname.includes("/book-landing-page/create"),
          })}
          style={
            !landingPageId &&
            !location.pathname.includes("/book-landing-page/create")
              ? { margin: "20px 0px 20px 0px" }
              : {}
          }
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
