import apiClient from "../base";
import { Endpoints } from "../../network";

export const forgotPasswordSendEmail = async ({
  send_code_by,
  email_or_phone,
}: {
  send_code_by: string;
  email_or_phone: string;
}) => {
  const response = await apiClient.post(
    `${Endpoints.forgotPasswordSendEmail}`,
    { send_code_by, email_or_phone }
  );

  return response?.data;
};

export const forgotPasswordCheckOtp = async ({ otp }: { otp: number }) => {
  const response = await apiClient.post(`${Endpoints.forgotPasswordCheckOtp}`, {
    verification_code: otp,
  });

  return response?.data;
};

export const forgotPasswordResetPassword = async ({
  verification_code,
  password,
}: {
  verification_code: number;
  password: string;
}) => {
  const response = await apiClient.post(
    `${Endpoints.forgotPasswordResetPassword}`,
    {
      verification_code,
      password,
    }
  );

  return response?.data;
};
